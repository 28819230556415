import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button']
  static values = {
    convenio: String
  }

  execute(e) {
    e.preventDefault()
    this.buttonTarget.disabled = true

    const buttonContent = this.buttonTarget.innerHTML
    this.buttonTarget.textContent = 'Aguarde...'
    
    const that = this

    // fetch permite receber o arquivo via ajax tratando como um blob
    fetch(`/convenios/${this.convenioValue}/formulario/inscricaos/attachments`, {
      method: 'GET',
      credentials: 'same-origin'
    })
    .then(response => response.blob())
    .then(blob => {
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = window.URL.createObjectURL(blob)
      // download attribute force to download file instead to browsing
      a.download = 'attachs-registrations.zip'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(a.href)

      that.buttonTarget.disabled = false
      that.buttonTarget.innerHTML = buttonContent
    })
    .catch(err => { 
      alert('error', err)
      that.buttonTarget.disabled = false
      that.buttonTarget.innerHTML = buttonContent
    })
  }

  connect() {
    console.log('START INSCRICAO')
  }
}