import { Controller } from "@hotwired/stimulus";

function downloadBlob(blob, filename) {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = window.URL.createObjectURL(blob);
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(a.href);
}

async function handleResponse(response, filename) {
  try {
    const blob = await response.blob();
    downloadBlob(blob, filename);
  } catch (err) {
    console.log(err);
  }
}

export default class extends Controller {
  static targets = ['button'];
  static values = {
    url: String,
    caption: String,
    filename: String,
  }

  execute(e) {
    e.preventDefault();
    this.buttonTarget.disabled = true;
    this.buttonTarget.innerHTML = 'Aguarde...';

    fetch(this.urlValue, {
      method: 'GET',
      credentials: 'same-origin',
    })
      .then(response => {
        if (response.ok) {
          handleResponse(response, this.filenameValue);
        } else {
          console.log('response != 200');
        }
        this.buttonTarget.disabled = false;
        this.buttonTarget.innerHTML = this.captionValue;
      })
      .catch(err => {
        console.log('error', err);
        this.buttonTarget.disabled = false;
        this.buttonTarget.innerHTML = this.captionValue;
      });
  }

  connect() {
    console.log('START GET BLOB');
  }
}