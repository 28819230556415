import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = []

  connect() {
    console.log('START POPOVER');

    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl, {
      html: true,
    }));
  }
}