import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    console.log('START TABLE DETAILS');
  }

  showHistoric(e) {
    const detail = e.currentTarget.parentNode.nextElementSibling;

    detail.classList.toggle('d-flex');
    detail.classList.toggle('d-none');
  }

}