import { Controller } from "@hotwired/stimulus";

function downloadBlob(blob, fileName) {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = window.URL.createObjectURL(blob);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(a.href);
}

async function handleResponse(response) {
  try {
    const format = response.headers.get('Content-Type');
    
    if (/text\/html/.test(format)) {
      const body = await response.text();
      // const $html = $(body).find('.card');
      // $('.container').html($html);
    
    } else if (/application\/pdf/.test(format)) {
      const blob = await response.blob();
      downloadBlob(blob, 'report.pdf');
    
    } else if (/application\/vnd/.test(format)) {
      const blob = await response.blob();
      downloadBlob(blob, 'report.xlsx');
    }

  } catch (err) {
    console.log(err);
  }
}

export default class extends Controller {
  static targets = [ 'form', 'button' ];
  static values = {
    url: String
  }

  create(e) {
    e.preventDefault();
    this.buttonTarget.disabled = true;
    this.buttonTarget.textContent = 'Aguarde...';

    fetch(this.urlValue, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content
      },
      body: new FormData(this.formTarget),
    })
    .then(response => { 
      if (response.ok) {
        handleResponse(response); 
      } else {
        console.log('response != 200');
      } 
      this.buttonTarget.disabled = false;
      this.buttonTarget.textContent = 'Gerar Relatório';
    })
    .catch(err => {
      console.log('error', err);
      this.buttonTarget.disabled = false;
      this.buttonTarget.textContent = 'Gerar Relatório';
    });
  }

  connect() {
    console.log('START REPORT');
  }
}