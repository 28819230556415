import { Controller } from "@hotwired/stimulus"
//import { useMutation } from "stimulus-use"

export default class extends Controller {
  static targets = ["itemContainer", "itemList"];
  static classes = ["hide"];

  connect() {
    console.log('START NEW ITEM');
    //useMutation(this, { debug: false, element: this.itemListTarget, childList: true })
  }

  showItem() {
    this.itemContainerTarget.classList.remove(this.hideClass);
  }

  hideItem() {
    this.itemContainerTarget.classList.add(this.hideClass);
  }

  toggle(e) {
    e.preventDefault();
    this.itemContainerTarget.classList.toggle(this.hideClass);
  }

  // mutate(entries) {
  //   for (const mutation of entries) {
  //     if (mutation.type === 'childList') {
  //       this.hideItem()
  //     }
  //   }
  // }

}