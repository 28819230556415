import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];

  connect() {
    console.log('START SHOW BUTTON CARD DETAIL');
  }

  show() {
    this.buttonTarget.classList.remove('d-none');
  }

  hide() {
    this.buttonTarget.classList.add('d-none');
  }
}