import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    console.log('START CARD DETAILS');
  }

  toggleDetail(e) {
    const detail = e.currentTarget.parentElement.parentElement.nextElementSibling.children[0];
    if (detail === undefined) return;
    detail.classList.toggle('d-none');
  }
}