import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'selectbox', 'field_number', 'field_row', 'field_options', 'field_required' ];

  select(e) {
    this.setFields();
  }

  connect() {
    console.log('START FORM FIELD');
    this.setFields();
  }

  // Show an element
  show(elem) {
    elem.style.display = 'block';
  }

  // Hide an element
  hide(elem) {
    elem.style.display = 'none';
  }

  // Toggle element visibility
  toggle(elem) {

    // If the element is visible, hide it
    if (window.getComputedStyle(elem).display === 'block') {
      this.hide(elem);
      return;
    }

    // Otherwise, show it
    this.show(elem);

  }

  setFields() {
    if (this.selectboxTarget.value == 'TextField') {
      this.show(this.field_rowTarget);
      this.hide(this.field_optionsTarget);
      this.show(this.field_requiredTarget);
      this.field_numberTargets.forEach(el => this.hide(el));

    } else if (this.selectboxTarget.value == 'H1Field') {
      this.hide(this.field_rowTarget);
      this.hide(this.field_optionsTarget);
      this.hide(this.field_requiredTarget);
      this.field_numberTargets.forEach(el => this.hide(el));
  
    } else if (this.selectboxTarget.value == 'H2Field') {
      this.hide(this.field_rowTarget);
      this.hide(this.field_optionsTarget);
      this.hide(this.field_requiredTarget);
      this.field_numberTargets.forEach(el => this.hide(el));

    } else if (this.selectboxTarget.value == 'DateField') {
      this.hide(this.field_rowTarget);
      this.hide(this.field_optionsTarget);
      this.show(this.field_requiredTarget);
      this.field_numberTargets.forEach(el => this.hide(el));

    } else if (this.selectboxTarget.value == 'IntegerField') {
      this.hide(this.field_rowTarget);
      this.hide(this.field_optionsTarget);
      this.show(this.field_requiredTarget);
      this.field_numberTargets.forEach(el => this.show(el));

    } else if (this.selectboxTarget.value == 'EmailField') {
      this.hide(this.field_rowTarget);
      this.hide(this.field_optionsTarget);
      this.show(this.field_requiredTarget);
      this.field_numberTargets.forEach(el => this.hide(el));

    } else if (this.selectboxTarget.value == 'CheckBoxField') {
      this.hide(this.field_rowTarget);
      this.hide(this.field_optionsTarget);
      this.show(this.field_requiredTarget);
      this.field_numberTargets.forEach(el => this.hide(el));

    } else if (this.selectboxTarget.value == 'SelectField') {
      this.hide(this.field_rowTarget);
      this.show(this.field_optionsTarget);
      this.show(this.field_requiredTarget);
      this.field_numberTargets.forEach(el => this.hide(el));

    } else if (this.selectboxTarget.value == 'RadioField') {
      this.hide(this.field_rowTarget);
      this.show(this.field_optionsTarget);
      this.show(this.field_requiredTarget);
      this.field_numberTargets.forEach(el => this.hide(el));

    } else if (this.selectboxTarget.value == 'FileField') {
      this.hide(this.field_rowTarget);
      this.hide(this.field_optionsTarget);
      this.show(this.field_requiredTarget);
      this.field_numberTargets.forEach(el => this.hide(el));

    } else {
      this.show(this.field_rowTarget);
      this.hide(this.field_optionsTarget);
      this.show(this.field_requiredTarget);
      this.field_numberTargets.forEach(el => this.hide(el));
    }
  }

  filterToNumber(e) {
    const value = e.target.value;
    const numbers = value.replace(/[^0-9]/g, '');
    e.target.value = numbers;
  }
}
