import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    hideOnSave: { type: Boolean, default: true },
  }

  connect() {
    console.log('START MODAL');
    this.modal = new bootstrap.Modal(this.element);
    this.modal.show();
  }

  disconnect() {
    this.modal.dispose();
  }

  submitEnd(e) {
    if (e.detail.success && this.hideOnSaveValue) {
      this.modal.hide();
    }
  }
}
